<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <div v-for="(data, i) in bloglist" :key="i" class="blog-list-item"
                @click="goto(data.link)">
                <div class="blog-list-img">
                  <img :src="data.poster" alt="">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'Methods to use same WhatsApp Account on Multiple phones',
          poster: require('@/assets/blog-1.webp'),
          desc: "If you're trying to use the same WhatsApp account on two different phones, the official WhatsApp app doesn't allow this. However, if you're using GB WhatsApp, a modified version of WhatsApp, you can indeed use the same account on multiple devices at once.",
          link: '/blog-1',
        },
        {
          title: 'How to Recover GB WhatsApp Data?',
          poster: require('@/assets/blog-2.webp'),
          desc: 'Many users prefer GB WhatsApp over the official app, primarily because of its enhanced features, especially for handling work-related or business conversations. This often means that the data stored on GB WhatsApp is more valuable and irreplaceable.',
          link: '/blog-2',
        },
        {
          title: 'Exciting Update: GBWhatsApp Introduces Channels Feature',
          poster: require('@/assets/blog-cv-3.webp'),
          desc: "GBWhatsApp has introduced an exciting new feature - Channels, similar to the ones on Telegram. This allows you to share information and engage with your audience in a streamlined way. ",
          link: '/blogs/GBWhatsApp-Channels',
        },
        {
          title: 'Common Questions About GB WhatsApp',
          poster: require('@/assets/blog-cv-4.webp'),
          desc: 'GB WhatsApp, also known as "WhatsApp GB" or "Ogmods," is a modified version of the standard WhatsApp application. It offers enhanced features such as customizable themes, the ability to hide your online status, and even the option to enable airplane mode within the app.',
          link: 'blog-4',
        },
        {
          title: "The 10 Apps to Spy WhatsApp Activity",
          poster: require('@/assets/blog-cv-5.webp'),
          desc: 'One of the most frequently asked questions online is how to spy someone else\'s WhatsApp conversations without needing access to their phone. Whether driven by curiosity or concern, people are eager to learn more about this. Today, we\'ll explore some tools designed for this purpose, while also discussing ethical considerations.',
          link: 'blog-5',
        },
      ],
    };
  },
  mounted () { },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
